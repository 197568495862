export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) return
  const { camper } = storeToRefs(useCamperStore())
  const { form } = storeToRefs(useBookingRequestFunnelStore())
  if (
    !form.value.details && !camper.value?.instant_booking_activated
  ) {
    return navigateTo({ name: 'f-campers-id_camper-request-message-owner', params: to.params, query: to.query })
  }
})
